
import { readUserProfile } from "@/store/main/getters";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Footer extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
