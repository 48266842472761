
import { Vue, Component } from "vue-property-decorator";
import I18nDropdown from "@/components/i18nDropdown.vue";
import { dispatchLogOut } from "@/store/main/actions";
import { commitSetOutsideHeaderMobileMenuOpen } from "@/store/main/mutations";
import {
  readOutsideHeaderMobileMenuOpen,
  readUserProfile,
} from "@/store/main/getters";

@Component({
  components: {
    I18nDropdown,
  },
})
export default class Header extends Vue {
  public currentPath: string = "";
  public showMobWallets: boolean = false;
  public showDexnetInfo: boolean = false;
  public showDexnetNumbers: boolean = false;
  public showMobileInfo: boolean = false;

  public mounted() {
    this.currentPath = this.$router.currentRoute.path;
  }

  public handleChangePath(path) {
    this.$router.push(path);
    this.currentPath = path;
  }

  public openMobileMenu() {
    commitSetOutsideHeaderMobileMenuOpen(this.$store, true);
    this.showMobWallets = false;
  }

  public closeMobileMenu() {
    commitSetOutsideHeaderMobileMenuOpen(this.$store, false);
    this.showMobWallets = false;
  }

  public async handleLogOut() {
    await dispatchLogOut(this.$store);
  }

  get isMobileMenuOpen() {
    return readOutsideHeaderMobileMenuOpen(this.$store);
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
