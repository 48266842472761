
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Main/Header.vue';
import HeaderMobileAside from '@/components/Main/HeaderMobileAside.vue';
import Footer from '@/components/Main/Footer.vue';
import { readUserProfile } from '@/store/main/getters';

@Component({
  components: {
    Header,
    HeaderMobileAside,
    Footer,
  },
})
export default class Main extends Vue {
  public mounted() {
    const user = readUserProfile(this.$store);
    this.$root.$i18n.locale = user && user.language ? user.language : 'en';
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
