
import { Vue, Component } from 'vue-property-decorator';
import I18nDropdown from '@/components/i18nDropdown.vue';
import { commitSetOutsideHeaderMobileMenuOpen } from '@/store/main/mutations';
import { readOutsideHeaderMobileMenuOpen } from '@/store/main/getters';

@Component({
  components: {
    I18nDropdown,
  },
})
export default class Header extends Vue {
  public currentPath: string = '';

  public mounted() {
    this.currentPath = this.$router.currentRoute.path;
  }

  public openMobileMenu() {
    commitSetOutsideHeaderMobileMenuOpen(this.$store, true);
  }

  public closeMobileMenu() {
    commitSetOutsideHeaderMobileMenuOpen(this.$store, false);
  }

  public handleChangePath(path) {
    this.$router.push(path);
    this.currentPath = path;
  }

  public handleOpenSignUp() {
    this.$emit('open-sign-up');
  }
  get isMobileMenuOpen() {
    return readOutsideHeaderMobileMenuOpen(this.$store);
  }
}
