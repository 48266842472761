
import { Vue, Component } from 'vue-property-decorator';
import I18nDropdown from '@/components/i18nDropdown.vue';
import { readOutsideHeaderMobileMenuOpen } from '@/store/main/getters';
import { commitSetOutsideHeaderMobileMenuOpen } from '@/store/main/mutations';
import { dispatchLogOut } from '@/store/main/actions';

@Component({
  components: {
    I18nDropdown,
  },
})
export default class HeaderMobileAside extends Vue {
  public currentPath: string = '';

  public mounted() {
    this.currentPath = this.$router.currentRoute.path;
  }

  public openMobileMenu() {
    commitSetOutsideHeaderMobileMenuOpen(this.$store, true);
  }

  public handleChangePath(path) {
    this.closeMenu();
    this.$router.push(path);
    this.currentPath = path;
  }

  public closeMenu() {
    commitSetOutsideHeaderMobileMenuOpen(this.$store, false);
  }

  public handleOpenSignUp() {
    this.$emit('open-sign-up');
  }

  public async handleLogOut() {
    await dispatchLogOut(this.$store);
  }

  get isOpen() {
    return readOutsideHeaderMobileMenuOpen(this.$store);
  }
}
